#map {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    width: 100%;
}

.map-content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#map-container {
    width: 100%;

    iframe {
        border: 0;
        width: 100%;
        height: 40vh;
    }
}