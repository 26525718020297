.header-nav {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #000;
    height: 75px;
    z-index: 999;
    transition: .4s ease-in-out;

    .brand-logo img {
        width: 60px;
        &:hover {
            cursor: pointer;
        }

    }

    .header-container {
        display: flex;
        width: 100%;
        justify-content: space-between;

        .logo {
            margin-top: 7px;
        }

        .menu {
            margin-top: 3px;
            ul li a {

                color: #E8B304;
                font-size: 1rem;
                font-weight: 400;

                &:hover {
                    font-weight: bold;
                }
            }
        }
        @media screen and(max-width: 992px) {
            .logo {
                margin-top: -52px;
            }
        }

    }
}

.transparent-header {
    background-color: rgba(0,0,0, .7);
}

.sidenav-trigger {
    i {
        margin-top: 5px;
        color: $golden-primary;
    }
}

#mobile-nav {
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left: -18px;
    width: 100%;

    a, i {
        color: $golden-primary;
        font-size: 1.2rem;
        font-weight: 600;
    } 
}

#sidenav-trigger {
    background-color: transparent;
    border: 0px;

    @media screen and (min-width: 992px) {
        display: none;        
    }
}