#hero {
    margin-top: 30px;
}

.erp {
    display: flex;
}

.erp-left {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

    a {
        color: $silver-primary;
        font-weight: bold;

        &:hover {
            text-decoration: underline;
        }
    }
}

.erp-right {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
}

.erp-image {
    margin-top:25px;
    width: 500px;

    &:hover {
        cursor: pointer;
    }
}

.yarl__navigation_prev, .yarl__navigation_next, .yarl__button {
    background-color: rgba(0, 0, 0, .3) !important;
}

@media screen and (max-width: 600px) {

    .erp-left-title {
        display: flex;
        align-items: center;
        width:100%;
        justify-content: center;
    }
    
}