#parallax {
    margin-top: 40px;
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: url("../../img/home/parallax.jpg") no-repeat fixed;
    background-size: cover;
}


.btn-parallax-confirm {
    background-color: #00c500 !important;
}

.btn-parallax-cancel {
    background-color: #FA8072 !important;
}