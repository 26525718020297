#form-contact {
    margin-top: 10px;
    margin-bottom: 30px;
}

.btn-message {
    width: 305px;
    height: 45px;
    background-color: transparent;
    border: 1px solid $golden-primary;

    &:hover {
        background-color: $golden-primary;
        transition: .4s ease-in-out;
        color: #FFF;
        cursor: pointer;
    }
}

.btn-message:focus {
    background-color: $golden-primary;
    color: #FFF;
}

.submit-form {
    margin-left: 10px;
    display: flex;
    justify-content: flex-start;
}

.input-field input:focus+label, textarea:focus+label {
    color: #000 !important;
}

.row .input-field input:focus, textarea:focus {
    border-bottom: 1px solid #E8B304 !important;
    box-shadow: 0 1px 0 0 #E8B304 !important
}