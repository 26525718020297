.services {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    margin-bottom: 25px;
}

.services-text span {
    font-size: 1.4rem;
}

.card {
    box-shadow: none;
    height: 350px;
    transition: .4s ease-in-out;

    i {
        display: flex;
        justify-content: center;
        padding: 15px;
        font-size: 80px;
        color: #E8B304;
    }

    a {
        color: #E8B304;
        font-weight: bold;

        &:hover {
            text-decoration: underline;
        }

    }

    &:hover {
        box-shadow: 0px 0px 5px 0px rgba(93, 93, 109, 1);
        cursor: pointer;
        transition: .4s ease-in-out;
    }

    .card-title {
        font-weight: bold;
    }
    
    .service-content p {
        text-align: center;
    }

    .slick-dots li button:before {
        color: orange;
      }
}