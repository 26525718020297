#deskflow {
    margin-top: 80px;
}

#deskflow-logo {
    width: 250px;
}

#deskflow-sublogo {
    width: 450px;
}

#deskflow-features {
    margin-top: 25px;
}

.module-card {
    background-color: #fff;
    cursor: pointer;
}

.module-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #5d5d5d;
    ;

    i {
        font-size: 5rem;
    }
}

.module-title {
    font-size: 1.2rem;
    font-weight: bold;
    display: flex;
    justify-content: center;
    color: #5d5d5d;
    margin-bottom: 15px
}

.module-icon i {
    color: #5d5d5d !important;
}

.module-description {
    text-align: center;
    color: #5d5d5d;
}

.carousel {
    margin-top: 50px;
}

#additional-modules {
    margin-top: -70px;
}

.stack-card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img {
        width: 50px;
    }
}

.carousel-stack {

    margin-top: 50px;
    transition: .4s ease-in-out;

    img {
        filter: grayscale(100);

        &:hover {
            filter: grayscale(0);
        }
        
    }

    .stack-description {
        margin-top: 15px;
    }
}

@media screen and (max-width: 400px) {

    #deskflow-logo {
        width: 150px;
    }
    
    #deskflow-sublogo {
        width: 250px;
        margin-bottom: 50px;
    }
}