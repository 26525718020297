#partners {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    flex-direction: column;
    padding: 20px;    
}

.partner-card {
    
    box-shadow: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding:15px;
    

    &:hover {
        cursor: pointer;
        transition: .4s ease-in-out;
    }

    .card-title {
        font-weight: bold;
    }
    
    .service-content p {
        text-align: center;
    }
}

.card-image {
    padding: 15px;
    
    img {
        width: 300px;
    }
}