.footer {
    background-color:#000 !important;
    padding:2rem 0;
}
.footer-tittle{

    color:#E8B304 !important
}
.footer-inner .footer-list {
    padding:0;
    margin:0;
    display:block
}

.footer-inner .footer-list li {
    display:block;
    color:#fff;
    margin-bottom:0.8rem
}
.footer-inner .footer-list li a:hover {
    text-decoration:underline
}

.footer-inner .footer-list-inline {
    display:block
}

.footer-inner .footer-list-inline li {

    display:inline-block;
    margin-right:1.8rem

}

.facebook-icon,.instagram-icon {
    font-size:30px;
    -webkit-transition:.4s;transition:.4s;color:#fff;
    position:absolute;
    z-index:1
}

.facebook-icon {
    margin-left:15px
}

.facebook-icon:hover {

    -webkit-transition:.4s;
    transition:.4s;color:#3E5C9A
}
.instagram-icon:hover {

    color:#A72CB5;
    -webkit-transition:.4s;transition:.4s

}

.scroll {
    background-color:#000;
    -webkit-transition:.4s;transition:.4s;
    -webkit-box-shadow:none;box-shadow:none
}

.footer-list a{
    color:#fff
}