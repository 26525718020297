.banner {
    background-color: #000;
    padding: 15px 10px;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.typewriter-text::after {
    content: '|';
    opacity: 1;
    margin-left: 5px;
    display: inline-block;
    animation: blink .7s infinite;
}

@keyframes blink {

    0%,
    100% {
        opacity: 1
    }

    50% {
        opacity: 0
    }
}


@keyframes slide {
    0% {
        transform: translateX(-25%);
    }

    100% {
        transform: translateX(25%);
    }
}

.squares li {
    width: 40px;
    height: 40px;
    background-color: rgba(255, 255, 255, 0.15);
    display: block;
    position: absolute;
    bottom: -30;
    animation: up 2s infinite alternate;
}


@keyframes up {
    from {
        opacity: 0;
        transform: translateY(0);

    }

    50% {
        opacity: 1;
    }

    to {
        opacity: 0;
        transform: translateY(-1000px) rotate(960deg);

    }
}