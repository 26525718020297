@import 'globals';
@import './header/header';
@import './banner/banner';
@import './services/services';
@import './hero/hero';
@import './footer/footer';
@import './parallax/parallax';
@import './partners/partners';
@import './map/map';
@import './contact/contact';
@import './cookie/cookie';
@import './deskflow/deskflow';

.loader-wrapper {
    width: 100%;
    height: 120vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #000;
    z-index: 999999 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;

    span {
        margin-top: 20px;
        color: $golden-primary;
        font-weight: bold;
    }
}

#preload {
    border-color: $golden-primary;
}

::-webkit-scrollbar {
	width: 5px
}

::-webkit-scrollbar-track {
	background: #000
}

::-webkit-scrollbar-thumb {
	background: $silver-primary
}

::-webkit-scrollbar-thumb:hover {
	background: $golden-primary;
}