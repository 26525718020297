.cookie-container {
    width: 100%;
    height: 150px;
    background-color: rgba(0, 0, 0, .8);
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 999999;
    opacity: 0;
    animation: fadeInAnimation 0.5s ease-in forwards;
}

@keyframes fadeInAnimation {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fadeIn {
    opacity: 1;
    transition: opacity 0.5s ease-in;
  }
    
  .fadeOut {
    opacity: 0;
    transition: opacity 0.5s ease-out;
    pointer-events: none;
  }

.cookie-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 150px;
    padding: 15px;

    span {
        color: #FFF;
        font-size: 1.2rem;
    }

    .cookie-buttons {

        button {
            width: 100px;
            height: 30px;
            background-color: transparent;
            border: 1px solid $golden-primary;
            color: #FFF;
            margin-right: 5px;
            margin-top: 5px;

            &:hover {
                cursor: pointer;
                background-color: $golden-primary;
                transition: .4s ease-in-out;
            }
        }

    }

}